<template>
    <div class="track-settings">
        <div class="track-setting">
            <div class="track-setting-header">
                <h3 for="">Transitions</h3>
                <div class="track-setting-description">Changing transitions times may improve the final result if closest match is</div>
                <div class="track-settings-info">
                    <filmstro-icon name="info-icon"/>
                    <a target="_blank" href="https://filmstro.com/app-settings">Watch a video on Transitions</a>
                </div>
                <!-- <filmstro-icon name="video-editing" type="image" /> -->
            </div>
            <div class="track-setting-options">
                <ButtonGroup :flexed="true" :grouped="true"  @button-click="setTrackTransitionTime($event)" :value="store.getters['edit/track_settings_transition']"
                    :buttons="[{ label: 'SMALL', value: 0.1 }, { label: 'MEDIUM', value: 1 }, { label: 'LARGE', value: 2 }, { label: 'XLARGE', value: 3 }]" />
            </div>
        </div>

        <div class="track-setting" v-if="false">
            <div class="track-setting-header">
                <label for="">Fade Out</label>
                <filmstro-icon name="audio-tail" type="image" />
            </div>
            <div class="track-setting-options">
                <ButtonGroup @button-click="setFadeout($event)" :value="store.getters['edit/track_settings_fade_out']"
                    :buttons="[{ label: 'SMALL', value: 0.5 }, { label: 'MEDIUM', value: 1 }, { label: 'LARGE', value: 3 }, { label: 'XLARGE', value: 5 }]" />
            </div>
        </div>

        <div class="track-setting">

            <div class="track-setting-header">
                <h3 for="">Cut Downs</h3>
                <div class="track-setting-description">Changing transitions times may improve the final result if closest match is</div>
                <div class="track-settings-info">
                    <filmstro-icon name="info-icon"/>
                    <a target="_blank" href="https://filmstro.com/app-settings">Watch a video on Cut Downs</a>
                </div>
                <!-- <filmstro-icon name="video-editing" type="image" /> -->
            </div>


            <div class="track-setting-options">
                <ButtonGroup :grouped="true" @button-click="setCutDowns($event)" :value="store.getters['edit/track_settings_cut_downs']"
                    :buttons="[{ label: 'SHORT', value: 2 }, { label: 'DEFAULT', value: 1 }]" />
            </div>
        </div>
    </div>
    <!-- <div class="track-settings-info">
        <h3>SAMPLE RATE CHANGE</h3>
        <div class="track-setting">
            <div class="track-setting-header">
                <label for="">Music Export</label>
                <filmstro-icon name="video-cog" type="image" />
            </div>
            <div class="track-setting-options">
                If your rendered file is playing back too fast/slow or high/low then you need to change your device
                sample rate. Visit <a target="blank" href="https://filmstro.com/samplerate">this page</a> for
                instructions
            </div>
        </div>
    </div> -->
</template>

<script setup>
import ButtonGroup from '../ButtonGroup.vue';
import { useStore } from 'vuex';
const store = useStore();
import useAudioEngine from '@/composables/useAudioEngine';
const { AudioEngine } = useAudioEngine();
const setInputElementValue = (element, value) => {
    let el = document.querySelector(element);
    if (el) {
        el.value = value;
    }
}

const setTrackTransitionTime = ( value ) => {
    // setInputElementValue('#skip-transition-time', value )
    store.commit('edit/setTrackSettingsTransition', value);
}
const setFadeout = ( value ) => {
    // setInputElementValue('#song-end-time', value )
    store.commit('edit/setTrackSettingsFadeout', value);
}
const setCutDowns = ( value ) => {
    // setInputElementValue('#cut-down-value', value)
    store.commit('edit/setTrackSettingsCutDowns', value);
    setTimeout(() => {
        AudioEngine.maybeUseAlgorithm();
        AudioEngine.debug_data();
    }, 150);

}

</script>


<style lang="scss">
.track-settings {
    padding-top: 10px;
}

.track-setting {
    // padding: 10px 20px;
    // background: #434650;
    border-radius: 20px;
    margin-bottom: 30px;
    font-weight: 300;
    label {
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: -0.5px;
    }



    img {
        width: 20px;
    }
}


</style>